import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  textareaInput,
  initialValue,
  createSection
} from '@formkit/inputs'

const textWrapper =  createSection('textWrapper', 'div')
const inputContainer =  createSection('inputContainer', 'div')

/**
 * Input definition for a textarea.
 * @public
 */
export const textarea: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      textWrapper(
        label('$label'),
        help('$help'),
      ),
      inputContainer(
        inner(
          icon('prefix', 'label'),
          prefix(),
          textareaInput(),
          suffix(),
          icon('suffix'),
        ),
        messages(message('$message.value'))
      )
    )
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: [],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'textarea',
  /**
   * Additional features that should be added to your input
   */
  features: [initialValue],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: 'b1n0td79m9g',
}
