<template>
  <div>
    <NuxtLayout>
      <div class="flex flex-col items-center gap-4 p-8 text-center">
        <p class="max-w-[38rem]">
          {{ error?.statusMessage || 'Ops. Something wrong happened ' }}
        </p>
        <div class="flex items-center gap-4">
          <UButton
            @click.prevent="handleError"
          >
            Go back to dashboard. 
          </UButton>
          <p>or</p>
          <UButton
            color="white"
            @click.prevent="clearAllCookies"
          >
            Reset application
          </UButton>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError
})

const handleError = async () => {
  await clearError({redirect:'/'})
}
function clearAllCookies() {
  const cookies = document.cookie.split(';');
  
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
  handleLogout()
}
const handleLogout = async () => { 

const { $auth0 } = useNuxtApp()
await $auth0.logout({
  logoutParams: {
    returnTo: window.location.origin === 'http://localhost:3000' ? 'http://localhost:3000' : 'https://deckmatch.com',
  },
});
}

</script>