import type { ClientInvestorResponse } from '@/types'

export const useCIStore = defineStore('ciStore', {
 state: () => ({
  clientInvestor: {} as ClientInvestorResponse,
  meta: {
    paused:false
  }
 }),
 getters: {
  getCI: (state) => state.clientInvestor,
  getMembers: (state) => state.clientInvestor.client_investor_member,
 },
  actions: {
    async fetchVerdict(clientInvestorId:number) {
      const authorization = await useSilentAuth()
      
      return await $fetch(`/api/client-investor/${clientInvestorId}`, {
        headers: {
          authorization
        },
        method: 'GET'
      }).then(res => {
          this.clientInvestor = res
          
        return res
      })
    },
  }
})