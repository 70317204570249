import { clone } from '@formkit/utils'

export function createLoadingSpinnerPlugin() {
  return function loadingSpinnerPlugin(node) {
    // bail if we're not a button
    if (node.props.family !== 'button') return
    
    node.addProps(['loading'])
    
    node.on('created', () => {
      const inputDefinition = clone(node.props.definition)
      const originalSchema = inputDefinition.schema
      const higherOrderSchema = (extensions) => {
        extensions.prefix = {
          $el: 'div',
          if: '$loading',
          attrs: {
            class: 'w-[1em] aspect-square border-[.15em] border-current border-r-transparent rounded-full mr-2 animate-spin'
          }
        }
        return originalSchema(extensions)
      }
      inputDefinition.schema = higherOrderSchema
      node.props.definition = inputDefinition
    })
  }
}