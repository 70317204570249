
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(updateLocale)
dayjs.locale('en')
dayjs.tz.setDefault('Norway/Oslo')


dayjs.updateLocale("en")

export default dayjs
