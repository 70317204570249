// plugins/tippy.ts
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueTippy, {
        defaultProps: {
            arrow: true
        },
        flipDuration: 0,
    })
})