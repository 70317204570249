import type { ClientInvestorMemberResponse_Success, CustomDataResponse, CustomDataCreate} from '@/types'

type Prettify<T> = {[K in keyof T]: T[K]} & {}
type test = Prettify<CustomDataResponse>
interface DeckUserStore  {
  deckmatchUser: {
    profile:ClientInvestorMemberResponse_Success,
    customFields: CustomDataResponse[],
    meta: {
      status: 'not_fetched' | 'fetching_get_error' | 'fetching_success' | 'fetching' | 'updating' | 'no_access'
      error?: {
        statusCode: 401 | 402 | 404 | 500,
        statusMessage: string
      }
    },
    profileStatus: { [key in 'user' | 'models' | 'industries' | 'countries'  | 'memo' ]: boolean }
  }
}

export const useDeckmatchUserStore = defineStore('deckmatchUser', {
 state: () => ({
   deckmatchUser: {
    profile: {},
    customFields: [] as CustomDataResponse[],
    meta: {
      status: 'not_fetched',
      error: {},
    },
    profileStatus: {
      user: true,
      models: true,
      industries: true,
      countries: true,
      memo: true
    }
   } 
 } as DeckUserStore),
 getters: {
  getProfile: (state) => state.deckmatchUser.profile,
  getClientInvestorId: (state) => state.deckmatchUser.profile.client_investor_id,
  isAdmin: (state) => state.deckmatchUser.profile.is_admin ?? false,
  getUserInfo:(state) => {
    const {first_name, last_name, email, phone_number } = state.deckmatchUser.profile
    return {
      first_name, last_name, email, phone_number
    }
  },
  getRules:(state) => {
    const {investment_business_models, investment_countries, investment_industries, investment_revenue_models, investment_stages, investment_technology_types, investment_thesis} = state.deckmatchUser.profile
    return {
      investment_business_models, investment_countries, investment_industries, investment_revenue_models, investment_stages, investment_technology_types, investment_thesis
    }
  },
  getMeta: (state) => state.deckmatchUser.meta,
  getProfileStatus: (state) => state.deckmatchUser.profileStatus, 
  getCustomFields: (state) => state.deckmatchUser.customFields,
  
 },
 actions: {
  getCustomFieldById (id: number | undefined) {
    return this.deckmatchUser.customFields.find((field) => field.id === id)
  },
  async fetchCustomFields() {
    this.deckmatchUser.meta = {
      status: 'fetching'
    }
    const authorization = await useSilentAuth()
   await $fetch('/api/custom-data/read', {
      method: 'GET',
      headers: {
        authorization
      }
    }).then((res) => {
      this.deckmatchUser.customFields = res
      this.deckmatchUser.meta = {
        status: 'fetching_success'
      }
    }).catch((error) => {
      this.deckmatchUser.meta = {
        status: 'fetching_get_error',
        error: {
          statusCode: error.statusCode,
          statusMessage: error.statusMessage
        }
      }
    })
  },
  async editCustomField(editedField: CustomDataResponse) {
    const id = editedField.id
    const editObject = {
      name: editedField.name,
      question: editedField.question,
      type: editedField.type,
      item_list_type: editedField.item_list_type,
      selection_items: editedField.selection_items,
    }

    const authorization = await useSilentAuth()


    return $fetch(`/api/custom-data/${id}`, {
      method: 'PUT',
      headers: {
        authorization,
      },
      body: editObject
    }).then((res) => {
      const index = this.deckmatchUser.customFields.findIndex((field) => field.id === id)
      this.deckmatchUser.customFields[index] = res
      return
    }).catch((error) => {
      throw new Error(error.statusMessage)
    })
  
  },
  async removeNewlyDeletedFieldFromList(id: number) {
    const authorization = await useSilentAuth()
    return $fetch(`/api/custom-data/${id}`, {
      method: 'DELETE',
      headers: {
        authorization,
      },
    }).then(() => {
      this.deckmatchUser.customFields = this.deckmatchUser.customFields.filter((field) => field.id !== id)
      return
    }).catch((error) => {
      throw new Error(error.statusMessage)
    })
    
  },
  async addNewField(newField: CustomDataCreate) {
    const authorization = await useSilentAuth()
    return $fetch('/api/custom-data/create', {
      method: 'POST',
      headers: {
        authorization,
      },
      body: newField
    }).then((res) => {
      this.deckmatchUser.customFields.push(res)
      return
    }
    ).catch((error) => {
      throw new Error(error.statusMessage)
    })
  },
  
  resetError() {
    this.deckmatchUser.meta = {
      status: 'not_fetched',
    }
  },
  setProfileStatus(value :ClientInvestorMemberResponse_Success) {
    this.deckmatchUser.profileStatus = {
      user: (!!value.first_name && !!value.last_name) || false,
      models: (value.investment_business_models && value.investment_business_models.length > 0) && (value.investment_revenue_models && value.investment_revenue_models.length > 0) && (value.investment_stages && value.investment_stages.length > 0) && (value.investment_technology_types && value.investment_technology_types.length > 0) || false,
      industries: value.investment_industries && value.investment_industries.length > 0 || false,
      countries: value.investment_countries && value.investment_countries.length > 0 || false,
      memo: value.investment_thesis && value.investment_thesis.length > 0 || false
    }
  },
  async updateUser(changes:Partial<ClientInvestorMemberResponse_Success>){

    const authorization = await useSilentAuth()
    let res

    const savedSettings = {...this.deckmatchUser.profile}
    
    try {
      res = await $fetch<Promise<ClientInvestorMemberResponse_Success>>('/api/deckmatch_user', {
        headers: {
          authorization
        },
        method: 'PUT',
        body: {
          ...savedSettings,
          ...changes
        }
      })
      
      this.deckmatchUser.profile = res
      this.setProfileStatus(res)
      this.deckmatchUser.meta = {
        status: 'fetching_success'
      }
      return res

    } catch (error) {
      console.log('error.statusMessage', error.statusMessage);
    }
  },
  async fetchUser() {
    const authorization = await useSilentAuth()
    try {
      const res = await $fetch<Promise<ClientInvestorMemberResponse_Success>>('/api/deckmatch_user', {
        headers: {
          authorization
        },
        method: 'GET'
      })
      if(res.first_name === '**value not set**') {
        res.first_name = ''
      }
      if(res.last_name === '**value not set**') {
        res.last_name = ''
      }
      this.deckmatchUser.profile = res
      this.setProfileStatus(res)
      this.deckmatchUser.meta = {
        status: 'fetching_success'
      }
      return res
      
    } catch (error) {
      console.log('error.statusMessage', error.statusMessage);
    }
  }
 }
})