<template>
  <NuxtLayout>
    <ul
      v-auto-animate 
      class="fixed top-20 md:top-0 right-0 z-40 flex flex-col gap-2 mt-4 mr-4 w-[350px] max-w-full"
    >
      <ToastComponent
        v-for="toast in toasts"
        :key="toast.id"
        :toast="toast"
      />
    </ul>
    <NuxtPage />
    <USlideovers />
    
    <UNotifications />
    <UModals />
  </NuxtLayout>
</template>
<script setup lang="ts">
import { useToastStore } from '@/stores/toastStore';
const isOpen = ref(true)

const toastStore = useToastStore();

const toasts = computed(() => toastStore.getToasts);
</script>

<!-- :component-example{component="slideover-example-composable"} -->