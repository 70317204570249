<template>
  <li
    tabindex="0"
    class="relative w-full mt-2 overflow-hidden bg-white border border-gray-100 shadow-xl rounded-xl"
    
    @mouseover="() => pause()"
    @mouseleave="() => resume()"
  >
    <div
      class="flex p-4"
      :class="props.toast.slim || (!props.toast.slim && !props.toast.iconName) ? 'pr-9 gap-4' : 'flex-col gap-2'"
    >
      <div
        v-if="props.toast.iconName"
        class="text-2xl"
      >
        <FadedIcon
          
          :icon-name="props.toast.iconName"
          :color="iconColor"
        />
      </div>
   
      <main class="flex flex-col ">
        <div
          id="dialog_header"
          class=""
        >
          <h3 class="text-sm font-semibold text-gray-900">
            {{ props.toast.title }}
          </h3>
          <div class="text-sm text-gray-600">
            {{ props.toast.text }}
          </div>
        </div>
    
        <div
          v-if="props.toast?.action || props.toast?.dismissible"
          class="flex flex-wrap w-full gap-4 mt-2 text-sm font-semibold "
        >
          <button
            class="hover:underline"
            @click="handleDeleteToast"
          >
            Dismiss
          </button>
          <button
            v-if="props.toast.action"
            class="text-primary-600 hover:underline"
            @click="async () => {
              if (props.toast?.action?.handler) {
                await props.toast.action.handler();
              }
              await handleDeleteToast();
            }"
          >
            {{ props.toast.action?.text }}
          </button>
        </div>
      </main>
      <button
        class="absolute text-gray-400 transition-all duration-75 rounded-full top-4 right-4 hover:text-gray-900 focus:outline-none aspect-square"
        @click="handleDeleteToast"
      >
        <span class="sr-only">Close</span>
        <div class="w-5 p-px ">
          <IconResolver icon-name="x" />
        </div>
      </button>
    </div>
    <div
      v-if="props.toast.dismissAfter"
      class="h-1.5 bg-primary-700"
      :style="{width:`${createProgressBar}%`}"
    />
  </li>
</template>

<script setup lang="ts">
import { useIntervalFn } from '@vueuse/core'

import type { Toast } from '~/types';
import { useToastStore } from '~/stores/toastStore';

const toastStore = useToastStore();

const props = defineProps<{toast:Toast}>()

const iconColor = computed(() => {
  switch (props.toast.type) {
    case 'error':
      return 'error'
    case 'success':
      return 'success'
    case 'warning':
      return 'warning'
    case 'info':
      return 'primary'
  
    default:
      return 'primary'
  }
});

const handleDeleteToast = () => {
  toastStore.removeToast(props.toast.id);
};

const timeCount = ref(props.toast.dismissAfter ? props.toast.dismissAfter : 0)
const { pause, resume } = useIntervalFn(() => {
  /* your function */
  timeCount.value -= 1
  if(timeCount.value === 0 && props.toast.dismissAfter){
    handleDeleteToast()
  }
}, 1)

const createProgressBar = computed(() => {
  if(props.toast.dismissAfter){
    return (timeCount.value / props.toast.dismissAfter) * 100
  }
  else {
    return 0
  }
})

onMounted(() => {
  if (!props.toast.dismissAfter) {
    pause()
  }
})
</script>
