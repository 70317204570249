import MarkdownIt from 'markdown-it';

export default defineNuxtPlugin(() => {
  const markdownService = new MarkdownIt();

  // Add custom renderer rule for links
  const defaultRender = markdownService.renderer.rules.link_open || 
    function(tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

  markdownService.renderer.rules.link_open = function(tokens, idx, options, env, self) {
    // Add target="_blank" and security attributes to all links
    tokens[idx].attrSet('target', '_blank');
    tokens[idx].attrSet('rel', 'noopener noreferrer nofollow');
    return defaultRender(tokens, idx, options, env, self);
  };

  return {
    provide: {
      markdown: markdownService
    }
  }
}) 