import { default as authorize6aqTeEDJMXMeta } from "/vercel/path0/apps/web/pages/authorize.vue?macro=true";
import { default as _91deckId_93VS3hqvYwmhMeta } from "/vercel/path0/apps/web/pages/deals/[deckId].vue?macro=true";
import { default as indexZqlAvGc1GMMeta } from "/vercel/path0/apps/web/pages/index.vue?macro=true";
import { default as loginK3GPdXgpRiMeta } from "/vercel/path0/apps/web/pages/login.vue?macro=true";
import { default as no_accessCbvIh4jqjHMeta } from "/vercel/path0/apps/web/pages/no_access.vue?macro=true";
import { default as custom_fieldsxz6MvlPPJzMeta } from "/vercel/path0/apps/web/pages/rules/custom_fields.vue?macro=true";
import { default as geography29WbabP21NMeta } from "/vercel/path0/apps/web/pages/rules/geography.vue?macro=true";
import { default as indexuhXGBsK4C3Meta } from "/vercel/path0/apps/web/pages/rules/index.vue?macro=true";
import { default as industriesJIjExW8kyyMeta } from "/vercel/path0/apps/web/pages/rules/industries.vue?macro=true";
import { default as nuance3WU0kxHFbsMeta } from "/vercel/path0/apps/web/pages/rules/nuance.vue?macro=true";
import { default as rulesJKLCftMG1CMeta } from "/vercel/path0/apps/web/pages/rules.vue?macro=true";
import { default as indexOh59ehnYIIMeta } from "/vercel/path0/apps/web/pages/settings/index.vue?macro=true";
import { default as stagesSb8WyDbXZ1Meta } from "/vercel/path0/apps/web/pages/settings/stages.vue?macro=true";
import { default as teamx5f4S6FlzqMeta } from "/vercel/path0/apps/web/pages/settings/team.vue?macro=true";
import { default as settingstwTvIciRODMeta } from "/vercel/path0/apps/web/pages/settings.vue?macro=true";
import { default as geography7sjNnq3CcqMeta } from "/vercel/path0/apps/web/pages/signup/geography.vue?macro=true";
import { default as indexBpj6mt2rkeMeta } from "/vercel/path0/apps/web/pages/signup/index.vue?macro=true";
import { default as industries15XZK62lePMeta } from "/vercel/path0/apps/web/pages/signup/industries.vue?macro=true";
import { default as modelseKVUWESf12Meta } from "/vercel/path0/apps/web/pages/signup/models.vue?macro=true";
import { default as nuancee7ZOKTQ3i9Meta } from "/vercel/path0/apps/web/pages/signup/nuance.vue?macro=true";
import { default as signupVJM412Zb3EMeta } from "/vercel/path0/apps/web/pages/signup.vue?macro=true";
import { default as verify_emailfNvDbWEwAjMeta } from "/vercel/path0/apps/web/pages/verify_email.vue?macro=true";
export default [
  {
    name: authorize6aqTeEDJMXMeta?.name ?? "authorize",
    path: authorize6aqTeEDJMXMeta?.path ?? "/authorize",
    meta: authorize6aqTeEDJMXMeta || {},
    alias: authorize6aqTeEDJMXMeta?.alias || [],
    redirect: authorize6aqTeEDJMXMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: _91deckId_93VS3hqvYwmhMeta?.name ?? "deals-deckId",
    path: _91deckId_93VS3hqvYwmhMeta?.path ?? "/deals/:deckId()",
    meta: _91deckId_93VS3hqvYwmhMeta || {},
    alias: _91deckId_93VS3hqvYwmhMeta?.alias || [],
    redirect: _91deckId_93VS3hqvYwmhMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/deals/[deckId].vue").then(m => m.default || m)
  },
  {
    name: indexZqlAvGc1GMMeta?.name ?? "index",
    path: indexZqlAvGc1GMMeta?.path ?? "/",
    meta: indexZqlAvGc1GMMeta || {},
    alias: indexZqlAvGc1GMMeta?.alias || [],
    redirect: indexZqlAvGc1GMMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginK3GPdXgpRiMeta?.name ?? "login",
    path: loginK3GPdXgpRiMeta?.path ?? "/login",
    meta: loginK3GPdXgpRiMeta || {},
    alias: loginK3GPdXgpRiMeta?.alias || [],
    redirect: loginK3GPdXgpRiMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: no_accessCbvIh4jqjHMeta?.name ?? "no_access",
    path: no_accessCbvIh4jqjHMeta?.path ?? "/no_access",
    meta: no_accessCbvIh4jqjHMeta || {},
    alias: no_accessCbvIh4jqjHMeta?.alias || [],
    redirect: no_accessCbvIh4jqjHMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/no_access.vue").then(m => m.default || m)
  },
  {
    name: rulesJKLCftMG1CMeta?.name ?? "Rules",
    path: rulesJKLCftMG1CMeta?.path ?? "/rules",
    meta: rulesJKLCftMG1CMeta || {},
    alias: rulesJKLCftMG1CMeta?.alias || [],
    redirect: rulesJKLCftMG1CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules.vue").then(m => m.default || m),
    children: [
  {
    name: custom_fieldsxz6MvlPPJzMeta?.name ?? "rules-custom_fields",
    path: custom_fieldsxz6MvlPPJzMeta?.path ?? "custom_fields",
    meta: custom_fieldsxz6MvlPPJzMeta || {},
    alias: custom_fieldsxz6MvlPPJzMeta?.alias || [],
    redirect: custom_fieldsxz6MvlPPJzMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules/custom_fields.vue").then(m => m.default || m)
  },
  {
    name: geography29WbabP21NMeta?.name ?? "rules-geography",
    path: geography29WbabP21NMeta?.path ?? "geography",
    meta: geography29WbabP21NMeta || {},
    alias: geography29WbabP21NMeta?.alias || [],
    redirect: geography29WbabP21NMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules/geography.vue").then(m => m.default || m)
  },
  {
    name: indexuhXGBsK4C3Meta?.name ?? "rules",
    path: indexuhXGBsK4C3Meta?.path ?? "",
    meta: indexuhXGBsK4C3Meta || {},
    alias: indexuhXGBsK4C3Meta?.alias || [],
    redirect: indexuhXGBsK4C3Meta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: industriesJIjExW8kyyMeta?.name ?? "rules-industries",
    path: industriesJIjExW8kyyMeta?.path ?? "industries",
    meta: industriesJIjExW8kyyMeta || {},
    alias: industriesJIjExW8kyyMeta?.alias || [],
    redirect: industriesJIjExW8kyyMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules/industries.vue").then(m => m.default || m)
  },
  {
    name: nuance3WU0kxHFbsMeta?.name ?? "rules-nuance",
    path: nuance3WU0kxHFbsMeta?.path ?? "nuance",
    meta: nuance3WU0kxHFbsMeta || {},
    alias: nuance3WU0kxHFbsMeta?.alias || [],
    redirect: nuance3WU0kxHFbsMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/rules/nuance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingstwTvIciRODMeta?.name ?? "Settings & Account Admin",
    path: settingstwTvIciRODMeta?.path ?? "/settings",
    meta: settingstwTvIciRODMeta || {},
    alias: settingstwTvIciRODMeta?.alias || [],
    redirect: settingstwTvIciRODMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: indexOh59ehnYIIMeta?.name ?? "settings",
    path: indexOh59ehnYIIMeta?.path ?? "",
    meta: indexOh59ehnYIIMeta || {},
    alias: indexOh59ehnYIIMeta?.alias || [],
    redirect: indexOh59ehnYIIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: stagesSb8WyDbXZ1Meta?.name ?? "settings-stages",
    path: stagesSb8WyDbXZ1Meta?.path ?? "stages",
    meta: stagesSb8WyDbXZ1Meta || {},
    alias: stagesSb8WyDbXZ1Meta?.alias || [],
    redirect: stagesSb8WyDbXZ1Meta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/settings/stages.vue").then(m => m.default || m)
  },
  {
    name: teamx5f4S6FlzqMeta?.name ?? "settings-team",
    path: teamx5f4S6FlzqMeta?.path ?? "team",
    meta: teamx5f4S6FlzqMeta || {},
    alias: teamx5f4S6FlzqMeta?.alias || [],
    redirect: teamx5f4S6FlzqMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/settings/team.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signupVJM412Zb3EMeta?.name ?? undefined,
    path: signupVJM412Zb3EMeta?.path ?? "/signup",
    meta: signupVJM412Zb3EMeta || {},
    alias: signupVJM412Zb3EMeta?.alias || [],
    redirect: signupVJM412Zb3EMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: geography7sjNnq3CcqMeta?.name ?? "signup-geography",
    path: geography7sjNnq3CcqMeta?.path ?? "geography",
    meta: geography7sjNnq3CcqMeta || {},
    alias: geography7sjNnq3CcqMeta?.alias || [],
    redirect: geography7sjNnq3CcqMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup/geography.vue").then(m => m.default || m)
  },
  {
    name: indexBpj6mt2rkeMeta?.name ?? "signup",
    path: indexBpj6mt2rkeMeta?.path ?? "",
    meta: indexBpj6mt2rkeMeta || {},
    alias: indexBpj6mt2rkeMeta?.alias || [],
    redirect: indexBpj6mt2rkeMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: industries15XZK62lePMeta?.name ?? "signup-industries",
    path: industries15XZK62lePMeta?.path ?? "industries",
    meta: industries15XZK62lePMeta || {},
    alias: industries15XZK62lePMeta?.alias || [],
    redirect: industries15XZK62lePMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup/industries.vue").then(m => m.default || m)
  },
  {
    name: modelseKVUWESf12Meta?.name ?? "signup-models",
    path: modelseKVUWESf12Meta?.path ?? "models",
    meta: modelseKVUWESf12Meta || {},
    alias: modelseKVUWESf12Meta?.alias || [],
    redirect: modelseKVUWESf12Meta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup/models.vue").then(m => m.default || m)
  },
  {
    name: nuancee7ZOKTQ3i9Meta?.name ?? "signup-nuance",
    path: nuancee7ZOKTQ3i9Meta?.path ?? "nuance",
    meta: nuancee7ZOKTQ3i9Meta || {},
    alias: nuancee7ZOKTQ3i9Meta?.alias || [],
    redirect: nuancee7ZOKTQ3i9Meta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/signup/nuance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: verify_emailfNvDbWEwAjMeta?.name ?? "verify_email",
    path: verify_emailfNvDbWEwAjMeta?.path ?? "/verify_email",
    meta: verify_emailfNvDbWEwAjMeta || {},
    alias: verify_emailfNvDbWEwAjMeta?.alias || [],
    redirect: verify_emailfNvDbWEwAjMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/verify_email.vue").then(m => m.default || m)
  }
]