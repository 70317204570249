<template>
  <div
    :class="[generateColors]"
    class="w-[.7em] rounded-full ring-[.3em] box-content p-[.2em]" 
  >
    <div
      class="max-w-full max-h-full min-w-full min-h-full aspect-square"
    >
      <IconResolver
        :icon-name="iconName"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconNames } from '@/types';

const props = defineProps<{
  iconName: IconNames;
  color: 'error' | 'success' | 'primary' | 'warning' | 'gray';
}>()


const generateColors = computed(() => {
  switch (props.color) {
    case 'error':
      return 'text-error-600 bg-error-100 ring-error-50';
    case 'success':
      return 'text-success-800 bg-success-100 ring-success-50';
    case 'warning':
      return 'text-warning-600 bg-warning-100 ring-warning-50';
    case 'gray':
      return 'text-gray-600 bg-gray-100 ring-gray-50';
    case 'primary':
      return 'text-primary-600 bg-primary-100 ring-primary-50';
    default:
      return 'text-primary-600 bg-primary-100 ring-primary-50';
  }
})
</script>