import {createAuth0Client} from '@auth0/auth0-spa-js'
export default defineNuxtPlugin(async (nuxtApp) => {
  const { auth0ClientId, auth0Issuer, auth0Audience, deckmatch, authOrigin } = useRuntimeConfig().public

  const auth0 = await createAuth0Client({
    domain: auth0Issuer,
    clientId: auth0ClientId,
    authorizationParams: {
      redirect_uri: `${authOrigin}/authorize`,
      scope: 'openid profile email offline_access',
      audience: auth0Audience,
      prompt: 'select_account',
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      useCookiesForTransactions:true
    },
  })

    // nuxtApp.vueApp.use(domtoimage)
  return {
      provide: {
          auth0
      }
  }
})