import type { Toast } from '@/types'


// generate test toast of each type in an array
const testToasts: Toast[] = [
  {
    id: 'testSlimSuccess',
    title: 'Updates have been made to your profile',
    text: 'Your team has made changes to your company profile since you last logged in.',
    type: 'success',
    dismissible: true,
    iconName: 'verified',
    slim: true,
    action: {
      text: 'View changes',
      handler: () => new Promise ((resolve) => {
        setTimeout(() => {
          return resolve()
        }, 2000)
      })
    }
  },
  {
    id: 'testError',
    title: 'Error',
    iconName: 'exclamation',
    text: 'This is an error toast',
    type: 'error',
    dismissible: true,
    dismissAfter: 5000,
  },
  {
    id: 'testSuccess',
    title: 'Warning',
    iconName: 'check',
    text: 'This is a warning toast',
    type: 'warning',
    dismissible: true,
    dismissAfter: 5000,
  },
  {
    id: 'testInfo',
    title: 'Info',
    iconName: 'check',
    text: 'This is an info toast',
    type: 'info',
    slim: true,
    dismissible: true,
    dismissAfter: 5000,
  },
];

export const useToastStore = defineStore('toast', {
 state: () => ({
  toasts: [] as Toast[],
  meta: {
    paused:false
  }
 }),
 getters: {
  getToasts: (state) => state.toasts,
 },
  actions: {
    addToast(toast: Omit<Toast, 'id'>) {
      this.$state.toasts.push({...toast, id: generateUniqueId()} as Toast)
    },
    removeToast(id: string) {
      // find and remove toast by id
      const index = this.$state.toasts.findIndex((toast) => toast.id === id)
      if(index !== -1) {
        this.$state.toasts.splice(index, 1)
      }
    },
    pauseToasts() {
      this.meta.paused = true
    },
    resumeToasts() {
      this.meta.paused = false
    },
  }
})